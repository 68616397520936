import { IonButton, IonCard, IonCardContent, IonModal } from '@ionic/react';
import { useContext } from 'react';
import { DataContext } from '../context/DataContext';
import { Preferences } from '@capacitor/preferences';
import './DataLoadError.css' ;
import { ENV_SETTINGS } from './../ENV_SETTINGS' ;

const restart = async () => {
  await Preferences.clear() ;
  window.location.href = '/' ;
};

const logout = async () => {
  await Preferences.remove( { key: ENV_SETTINGS.NS + '.token' } ) ;
  window.location.href = '/' ;
};

export const DataLoadError: any = () => {
  const {
    dataLoadErrorMsg ,
    dataLoadErrorShow ,
    dataLoadErrorShowChange ,
  } = useContext(DataContext);

  return(
      <IonModal key="dataLoadError"
        isOpen = { dataLoadErrorShow }
        backdropDismiss = { true }
      >
        <IonCard>
          <IonCardContent
            style = {
              {
                fontSize: '1.2em' ,
                fontWeight: 'bold'
              }
            }
          >
            { dataLoadErrorMsg }
          </IonCardContent>
          <IonCardContent>
            <IonButton
              onClick = { () => logout() }
            >
              Wieder anmelden (am selben Server)
            </IonButton>
            <IonButton
              onClick = { () => restart() }
            >
              Neu anmelden (an neuem Server)
            </IonButton>
            <IonButton
              onClick = { () => dataLoadErrorShowChange( false ) }
            >
              Schließen
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonModal>
    )
};

