import { DataContext } from '../context/DataContext';
import { useContext, useState, useEffect } from 'react';

import {
    IonButton,
    IonChip,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPopover,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/react';

import { Preferences } from '@capacitor/preferences';
import { trashOutline, saveOutline, addCircleOutline } from 'ionicons/icons';
import { dbQuery } from '@saint/sqlite-worker';
import { Warning } from '@mui/icons-material';
import { useFirstRender } from '@mui/x-data-grid';
import { bworkerQuery } from '@saint/fetch-worker';

type Params = {
    artikel?: any;
    zusatzstoffe?: any;
};

//@ts-ignore
const delay = ms  => new Promise(res => setTimeout(res, ms));

export const LieferantArtikelUebersichtArtikel: React.FC<Params> = ({ artikel, zusatzstoffe }) => {
    let date = new Date();
    let sql_today= date.getUTCFullYear()         + '-' +
    (date.getUTCMonth() + 1)  + '-' +
    (date.getUTCDate())  
    const { appChosenKundenId,server,DBInitedChange } = useContext(DataContext);

    const [localArtikelZusatzstoffe, setLocalArtikelZusatzstoffe]: any = useState([]);
    const [localToggleSpeichern, setLocalToggleSpeichern]: any = useState(false);
    const [showZusatzstoffModal, setShowZusatzstoffModal]: any = useState(false);

    const [localArtikelId, setLocalArtikelId]: any = useState(artikel ? artikel.artikel_id : (appChosenKundenId+'-neu-'+Math.ceil(Math.random()*100000)));
    const [localArtikelBestellnummer, setLocalArtikelBestellnummer]: any = useState(
        artikel ? artikel.artikel_lieferanten_artikel_nr : null,
    );
    const [localArtikelBezeichnung, setLocalArtikelBezeichnung]: any = useState(
        artikel ? artikel.artikel_bezeichnung : null,
    );
    const [localArtikelBeschreibung, setLocalArtikelBeschreibung]: any = useState(
        artikel ? artikel.artikel_beschreibung : null,
    );
    const [localArtikelBestelleinheit, setLocalArtikelBestelleinheit]: any = useState(
        artikel ? artikel.artikel_bestelleinheit : 'GBND',
    );
    const [localArtikelInhaltBestelleinheit, setLocalArtikelInhaltBestelleinheit]: any = useState(
        artikel ? artikel.artikel_inhalt_bestelleinheit : 1,
    );

    const [localArtikelInhaltBestelleinheitString, setLocalArtikelInhaltBestelleinheitString]: any = useState(
        artikel ? artikel.artikel_inhalt_bestelleinheit : 1,
    );
    const [localArtikelInhaltEinheit, setLocalArtikelInhaltEinheit]: any = useState(
        artikel ? artikel.artikel_basiseinheit : 'STK',
    );
    const [localArtikelInhaltBasisgewicht, setLocalInhaltArtikelBasisgewicht]: any = useState(
        artikel ? 1000 * artikel.artikel_rezept_faktor : 1000,
    );
    const [localArtikelPreis, setLocalArtikelPreis]: any = useState(artikel ? artikel.preise_preis : 0);
    const [localArtikelPreisAb, setLocalArtikelPreisAb]: any = useState(artikel ? artikel.preise_ab : sql_today);
    
    const [localArtikelPreisString, setLocalArtikelPreisString]: any = useState(parseFloat(localArtikelPreis).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    }));
    const [localArtikelSteuer, setLocalArtikelSteuer]: any = useState(artikel ? artikel.artikel_steuer_id : 1);
    const [localArtikelKCal, setLocalArtikelKCal]: any = useState(artikel ? artikel.artikel_kcal : 0);
    const [localArtikelKH, setLocalArtikelKH]: any = useState(artikel ? artikel.artikel_kohlenhydrate : 0);
    const [localArtikelFett, setLocalArtikelFett]: any = useState(artikel ? artikel.artikel_fett : 0);
    const [localArtikelGesFett, setLocalArtikelGesFett]: any = useState(artikel ? artikel.artikel_ges_fetts : 0);
    const [localArtikelProtein, setLocalArtikelProtein]: any = useState(artikel ? artikel.artikel_protein : 0);
    const [localArtikelSalz, setLocalArtikelSalz]: any = useState(artikel ? artikel.artikel_salz : 0);
    const [localArtikelZucker, setLocalArtikelZucker]: any = useState(artikel ? artikel.artikel_zucker : 0);
    const [localArtikelGesperrt, setLocalArtikelGesperrt]: any = useState(artikel ? artikel.artikel_gesperrt : 0);
    
    function nichtErlaubteZeichen (string:any){
        let neuerstring=
            string.replaceAll('/','')
            .replaceAll('\\','')
            .replaceAll('ß','')
            .replaceAll('%','')
            .replaceAll("'",'')
            .replaceAll('"','')
            .replaceAll('`','')
            .replaceAll('´','')
            .replaceAll('^','')
            .replaceAll('°','')
            .replaceAll('<','')
            .replaceAll('>','')

        if(neuerstring!==string){
            alert(`Verbotenes Sonderzeichen bitte entfernen!
            ß / \ % ´\` ^ ° < > sind nicht erlaubt
      `)
        }
        return neuerstring
    }

    const artikel_id = 
      artikel ? artikel.artikel_id : 'neuer_artikel'
    
    function ladeZusatzstoffe() {
        dbQuery(
            `SELECT * FROM saint_zusatzstoffe_artikel,  saint_zusatzstoffe
    WHERE saint_zusatzstoffe_artikel.zusatzstoffe_id = saint_zusatzstoffe.zusatzstoff_id
    AND saint_zusatzstoffe_artikel.artikel_id = '${localArtikelId}'`,
            setLocalArtikelZusatzstoffe,
        );
    }

    function zusatzstoffHinzufuegen(zusatzstoff: any, artikelId: any) {
        dbQuery(`INSERT INTO saint_zusatzstoffe_artikel (zusatzstoffe_artikel_id, zusatzstoffe_id, artikel_id)
    VALUES ('${appChosenKundenId + '-' + Math.random()}', '${zusatzstoff}', '${localArtikelId}');`);
        ladeZusatzstoffe();
    }

    function deleteZusatzstoff(zusatzstoffId: any) {
        dbQuery(`DELETE FROM saint_zusatzstoffe_artikel
    WHERE saint_zusatzstoffe_artikel.zusatzstoffe_artikel_id = '${zusatzstoffId}'`);
        ladeZusatzstoffe();
        setLocalToggleSpeichern(true);
    }

    const saveArtikel = async () => {
        const artikelDaten = {
            localArtikelKCal:localArtikelKCal,
            localArtikelKH:localArtikelKH,
            localArtikelFett:localArtikelFett,
            localArtikelGesFett:localArtikelGesFett,
            localArtikelProtein:localArtikelProtein,
            localArtikelSalz:localArtikelSalz,
            localArtikelZucker:localArtikelZucker,
            localArtikelId:localArtikelId,
            localArtikelBestellnummer:localArtikelBestellnummer,
            localArtikelBezeichnung:localArtikelBezeichnung,
            localArtikelBeschreibung:localArtikelBeschreibung,
            localArtikelBestelleinheit:localArtikelBestelleinheit,
            localArtikelInhaltBestelleinheit:localArtikelInhaltBestelleinheit,
            localArtikelInhaltEinheit:localArtikelInhaltEinheit,
            localArtikelInhaltBasisgewicht:localArtikelInhaltBasisgewicht,
            appChosenKundenId:appChosenKundenId,
            localArtikelZusatzstoffe:localArtikelZusatzstoffe,
            localArtikelPreis:localArtikelPreis,
            localArtikelPreisAb:localArtikelPreisAb,
            localArtikelSteuer:localArtikelSteuer,
            localArtikelGesperrt:localArtikelGesperrt,
        }

        bworkerQuery(
            {
              url:
                server + '/funktionen/api2/lieferanten/set_artikel.php'
                + '?data=' + JSON.stringify(artikelDaten)
              ,
              method: 'GET'
            }
          )
          setLocalToggleSpeichern(false);

          if(artikel_id=='neuer_artikel'){
            alert('Artikel wird angelegt')
            await delay(1000);
            

            DBInitedChange(false);
          }
          
        
    }

    function Artikelclear() {
        setLocalArtikelBestellnummer(artikel ? artikel.artikel_lieferanten_artikel_nr : null);
        setLocalArtikelBezeichnung(artikel ? artikel.artikel_bezeichnung : null);
        setLocalArtikelBeschreibung(artikel ? artikel.artikel_beschreibung : null);
        setLocalArtikelBestelleinheit(artikel ? artikel.artikel_bestelleinheit : 'GBND');
        setLocalArtikelInhaltBestelleinheit(artikel ? artikel.artikel_inhalt_bestelleinheit : 1);
        setLocalArtikelInhaltBestelleinheitString(artikel ? artikel.artikel_inhalt_bestelleinheit : 1);
        setLocalArtikelInhaltEinheit(artikel ? artikel.artikel_basiseinheit : 'STK');
        setLocalArtikelSteuer(artikel.artikel_steuer_id ?  artikel.artikel_steuer_id : 1);
        setLocalInhaltArtikelBasisgewicht(artikel ?  artikel.artikel_rezept_faktor * 1000 : 1000);
        setLocalArtikelPreisString(artikel ? artikel.preise_preis : 0);
        setLocalArtikelGesperrt(artikel ? artikel.artikel_gesperrt : 0);
        setLocalArtikelPreis(artikel ? artikel.preise_preis : 0);
        setLocalToggleSpeichern(false);
    }

    useEffect(() => {
        ladeZusatzstoffe();
        // console.log('zusatzstoffe', zusatzstoffe.values);
    }, []);

    useEffect(() => {
        console.log('localArtikelSteuer',localArtikelSteuer)
        
    }, [localArtikelSteuer]);

    useEffect(() => {
        if(typeof(localArtikelPreisString) === 'string'){
            setLocalArtikelPreis(localArtikelPreisString.replace(/[^0-9,-]+/g,"").replace(',','.'))
        }
        
        
    }, [localArtikelPreisString]);
    return (
        <tr id={artikel_id} style={{}}>
            <td
                className="datacell"
                style={{
                    position: 'sticky',
                    left: 0,
                    border:artikel_id=='neuer_artikel'?'2px red solid':'',
                    backgroundColor:'white',
                    zIndex: 4,
                    maxWidth: '100px',
                    minWidth: '100px',
                }}>
                    {artikel_id=='neuer_artikel'?<><b>Neuer Artikel</b><br /></>:null}
                {localToggleSpeichern ? (
                    <>
                        <IonButton onClick={
                                ()=>
                                {
                                    saveArtikel()
                                }
                            }

                            id="knopfSpeichern">
                            <IonIcon
                                color="success"
                                icon={saveOutline}
                                
                            />
                        </IonButton>
                        <br />
                        <IonButton
                            onClick={() => {
                                Artikelclear();
                            }}
                            style={{ backgroundColor: 'alert' }}>
                            <IonIcon
                                color="warning"
                                icon={trashOutline}
                            />
                        </IonButton>
                    </>
                ) : (
                    <>&nbsp;</>
                )}
            </td>
            <td
                className="datacell"
                style={{ position: 'sticky', left: '100px', backgroundColor: 'white', zIndex: 4,
                border:artikel_id=='neuer_artikel'?'2px red solid':'',
            }}>
                <IonItem>
                    <IonInput
                        disabled={artikel_id=='neuer_artikel'?false:true}
                        class="datacellinput"
                        placeholder="Bestellnummer"
                        onIonChange={(e) => {

                            setLocalArtikelBestellnummer(nichtErlaubteZeichen(e.target.value));
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelBestellnummer}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelBezeichnung===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonTextarea
                        class="datacellinput"
                        placeholder="Bezeichnung"
                        autoGrow={true}
                        onIonChange={(e) => {
                            setLocalArtikelBezeichnung(nichtErlaubteZeichen(e.target.value));
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelBezeichnung}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:'2px green solid'}}>
                <IonItem>
                    <IonTextarea
                        class="datacellinput"
                        placeholder="Beschreibung"
                        autoGrow={true}
                        onIonChange={(e) => {
                            setLocalArtikelBeschreibung(nichtErlaubteZeichen(e.target.value));
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelBeschreibung}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelBestelleinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Bestelleinheit"
                        onIonChange={(e) => {
                            setLocalArtikelBestelleinheit(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelBestelleinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltBestelleinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Inhalt"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelInhaltBestelleinheit(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelInhaltBestelleinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltEinheit===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Einheit Inhalt"
                        type="text"
                        
                        onIonChange={(e) => {
                            setLocalArtikelInhaltEinheit(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelInhaltEinheit}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelInhaltBasisgewicht===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Gewicht Inhalt"
                        type="number"
                        onIonChange={(e) => {
                            setLocalInhaltArtikelBasisgewicht(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelInhaltBasisgewicht}
                    />
                </IonItem>
            </td>
            <td
                className="datacell"
                style={{border:'2px green solid'}}
               >
                {localArtikelZusatzstoffe.values.length > 0
                    ? //@ts-ignore
                      localArtikelZusatzstoffe.values.map((zusatzstoff: any) => {
                          return (
                              <IonChip style={{float:'left'}}>
                                  <IonLabel>{zusatzstoff.zusatzstoff_bezeichnung}</IonLabel>
                                  <IonIcon
                                      onClick={() => deleteZusatzstoff(zusatzstoff.zusatzstoffe_artikel_id)}
                                      icon={trashOutline}
                                  />
                              </IonChip>
                          );
                      })
                    : 'Keine Deklaration'}

                <IonButton
                    size={'small'}
                    onClick={() => {
                        setShowZusatzstoffModal(true);
                    }}>
                    <IonIcon icon={addCircleOutline} />
                </IonButton>
                <IonModal isOpen={showZusatzstoffModal}>
                    {zusatzstoffe.values.length > 0 ? (
                        <IonList style={{ height: '60vh', overflow: 'auto' }}>
                            {
                                //@ts-ignore
                                zusatzstoffe.values.map((zusatzstoff: any) => {
                                    return (
                                        <IonItem
                                            onClick={() =>
                                                {zusatzstoffHinzufuegen(zusatzstoff.zusatzstoff_id, localArtikelId)
                                                setLocalToggleSpeichern(true);}

                                            }>
                                            {zusatzstoff.zusatzstoff_bezeichnung}
                                        </IonItem>
                                    );
                                })
                            }
                        </IonList>
                    ) : (
                        'keine Zusatzstoffe angelegt'
                    )}

                    {/*
                        //@ts-ignore
                        zusatzstoffe.values.length > 0 ? (
                                <IonList>
                        {
                                //@ts-ignore
                                localLieferantenArtikelUebersicht.map((zusatzstoff: any) => {
                                    return (
                                       <IonItem>{zusatzstoff.zusatzstoff_bezeichnung}</IonItem>
                                    );
                                })}</IonList>
                            
                            
                                ) : (
                                "keine Zusatzstoffe angelegt"
                                )*/}
                </IonModal>
            </td>
            <td className="datacell" style={{border:localArtikelPreis===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Preis je VE"
                        type="text"
                        onIonBlur={(e) => {
                            //@ts-ignore
                            setLocalArtikelPreisString(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={parseFloat(localArtikelPreis).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        })}
                    />
                </IonItem>
            </td>
            <td className="datacell" >
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Preis ab"
                        type="date"
                        onIonBlur={(e) => {
                            //@ts-ignore
                            setLocalArtikelPreisAb(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelPreisAb}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelSteuer===null?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonSelect value={localArtikelSteuer} onIonChange={(e)=>setLocalArtikelSteuer(e.target.value)} aria-label="Steuer" placeholder="Steuersatz wählen">
                        <IonSelectOption value={1}>7%</IonSelectOption>
                        <IonSelectOption value={2}>19%</IonSelectOption>
                        <IonSelectOption value={3}>10,5%</IonSelectOption>
                        <IonSelectOption value={4}>0%</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelKCal===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Kcal je 100g"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelKCal(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelKCal}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelFett===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Fett in g je 100g"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelFett(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelFett}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelGesFett===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="ges. Fett je 100g"
                        size={140}
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelGesFett(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelGesFett}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelKH===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Kohlenhydr. in g je 100g"
                        onIonChange={(e) => {
                            setLocalArtikelKH(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelKH}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelZucker===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Zucker je 100g"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelZucker(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelZucker}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelProtein===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Eiweiß je 100g"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelProtein(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelProtein}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:localArtikelSalz===0?'2px red solid':'2px green solid'}}>
                <IonItem>
                    <IonInput
                        class="datacellinput"
                        placeholder="Salz je 100g"
                        type="number"
                        onIonChange={(e) => {
                            setLocalArtikelSalz(e.target.value);
                            setLocalToggleSpeichern(true);
                        }}
                        value={localArtikelSalz}
                    />
                </IonItem>
            </td>
            <td className="datacell" style={{border:artikel_id=='neuer_artikel'?'2px red solid':''}}>
                <IonItem>
                    <IonSelect 
                        value={localArtikelGesperrt} 
                        onIonChange={
                            (e)=>
                            {
                                setLocalArtikelGesperrt(e.target.value)
                                setLocalToggleSpeichern(true);
                            }
                        } 
                        aria-label="Aktiv" 
                        placeholder="aktiv?">
                        <IonSelectOption value={1}>nein</IonSelectOption>
                        <IonSelectOption value={0}>ja</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </td>
        </tr>
    );
};



