/** arrDbTables: Daten für Anlegen und Upaten SQLite-Tabellen
 *    - pro Table ein Array mit
 *      - Col 1: Tabellenname
 *      - Col 2: Tabellenfelder gemäß Syntax für CREATE TABLE
 *      - Col 3: optional: etwaige Indexe auf Tabelle - sowohl für CREATE wie DROP
 *        - Array aus Paaren mit Index-Name und Index-Targets
 *    - **! Vorhandene Tabellendefinitionen mit "system_"-Präfix dürfen nicht geändert werden**
 *    - Bsp.:
 *      - [ `saint_artikel` , `( artikel_id INTEGER PRIMARY KEY NOT NULL, artikel_lieferanten_artikel_nr VARCHAR NOT NULL , artikel_lieferanten_id INTEGER NOT NULL ) ;` , [ [ `artikel_lieferanten_artikel_nr_artikel_lieferanten_id` , `artikel_lieferanten_artikel_nr, artikel_lieferanten_id` ] ]
]
 */
/** Anzulegende Tabellen */
export const arrDbTables:Array<any> = [
  [ 'saint_artikel' ,
    `
      (
        artikel_id VARCHAR PRIMARY KEY,
        artikel_lieferanten_artikel_nr VARCHAR,
        artikel_lieferanten_id INTEGER,
        artikel_bezeichnung TEXT,
        artikel_beschreibung TEXT,
        artikel_steuer_id INTEGER,
        artikel_bestelleinheit TEXT,
        artikel_basiseinheit TEXT,
        artikel_inhalt_bestelleinheit VARCHAR ,
        artikel_rezept_faktor VARCHAR,
        artikel_naehrwert_faktor VARCHAR,
        artikel_kcal VARCHAR,
        artikel_fett VARCHAR,
        artikel_ges_fetts VARCHAR,
        artikel_kohlenhydrate VARCHAR,
        artikel_zucker VARCHAR,
        artikel_protein VARCHAR,
        artikel_salz VARCHAR,
        artikel_datenblatt INTEGER,
        artikel_gesperrt INTEGER,
        artikel_vk_preis VARCHAR,
        artikel_anbruch INTEGER,
        artikel_vorlaufzeit INTEGER,
        artikel_preisfaktor VARCHAR,
        artikel_datenblattlink TEXT,
        artikel_bildlink TEXT,
        artikel_last_update DATETIME,
        artikel_verhandelt VARCHAR,
        preise_preis VARCHAR
      );
    ` ,
    [ [ `artikel_id` , `artikel_id` ] ]
  ],
  [ 'saint_user_lieferant' ,
  `
    (
      user_lieferant_id INTEGER PRIMARY KEY,
      user_lieferant_user_id INTEGER,
      user_lieferant_kunde VARCHAR,
      user_lieferant_lieferant_id INTEGER
    );
  ` ,
  [ [ `user_lieferant_id` , `user_lieferant_id` ] ]
],[ 'saint_preise' ,
`
  (
    preise_id VARCHAR PRIMARY KEY,
    preise_artikel_id VARCHAR,
    preise_ab DATE,
    preise_bis DATE,
    preise_preis VARCHAR
  );
` ,
[ [ `preise_artikel_id` , `preise_artikel_id` ] ]
],[ 'saint_zusatzstoffe' ,
`
 (
    zusatzstoff_id	VARCHAR  NOT NULL ,	
    zusatzstoff_bezeichnung	VARCHAR NOT NULL ,
    zusatzstoff_typ	INTEGER  NOT NULL ,	
    zusatzstoff_beschreibung	VARCHAR NOT NULL ,
    zusatzstoffe_anzeige	VARCHAR NOT NULL
  ); 
`,
[ [ `zusatzstoff_id` , `zusatzstoff_id` ] ]
],[ 'saint_zusatzstoffe_artikel' ,
`
 (
  zusatzstoffe_artikel_id	VARCHAR  NOT NULL ,	
  zusatzstoffe_id	VARCHAR NOT NULL ,
  artikel_id	VARCHAR  NOT NULL 
  ); 
`,
[ [ `zusatzstoffe_artikel_id` , `zusatzstoffe_artikel_id` ] ]
]
  
  
  
  /*,
  [ 'saint_aufgaben' ,
    `
      (
        aufgaben_id	VARCHAR PRIMARY KEY,
        aufgaben_user VARCHAR,
        aufgaben_typ INTEGER,
        aufgaben_bezug INTEGER,
        aufgaben_status INTEGER,
        aufgaben_prio INTEGER,
        aufgaben_beschreibung TEXT,
        aufgaben_faellig DATE,
        aufgaben_aenderung DATETIME,
        aufgaben_ersteller VARCHAR,
        aufgaben_info VARCHAR,
        aufgaben_benachrichtigung INTEGER,
        aufgaben_benachrichtigung_ersteller INTEGER,
        aufgaben_bewertung TEXT,
        aufgaben_ist TEXT,
        aufgaben_rechnung VARCHAR,
        aufgaben_unter_techniker BLOB,
        aufgaben_unter_kunde BLOB,
        aufgaben_gesamt INTEGER,
        aufgaben_erstell_datum DATETIME,
        aufgaben_freigabe INTEGER,
        aufgaben_erledigung DATETIME, 
        aufgaben_loesung TEXT,
        aufgaben_rsp_name TEXT,
        aufgaben_rsp_telefon TEXT,
        aufgaben_bestellt INTEGER,
        aufgaben_xml INTEGER,
        aufgaben_edi INTEGER,
        aufgaben_ordner INTEGER,
        aufgaben_reklamiert INTEGER,
        aufgaben_abgerechnet INTEGER
      );
    ` ,
    [ [ `aufgaben_id` , `aufgaben_id` ] , [ `aufgaben_faellig` , `aufgaben_faellig` ],  [ `aufgaben_faellig_typ` , `aufgaben_faellig,aufgaben_typ` ] , [ `aufgaben_faellig_typ_bezug` , `aufgaben_faellig,aufgaben_typ,aufgaben_typ` ]]
    
  ] ,
  [ 'saint_aufgaben_positionen' ,
    `
      (
        aufgaben_positionen_id VARCHAR PRIMARY KEY,
        aufgaben_positionen_aufgabe_id VARCHAR,
        aufgaben_positionen_interne_artikel_id VARCHAR,
        aufgaben_positionen_lieferant_artikel_id VARCHAR,
        aufgaben_positionen_lieferant_id VARCHAR,
        aufgaben_positionen_bezeichnung	TEXT,
        aufgaben_positionen_menge VARCHAR,
        aufgaben_position_einheit VARCHAR,
        aufgaben_positionen_typ INTEGER,	
        aufgaben_positionen_start DATETIME,
        aufgaben_positionen_ende DATETIME,
        aufgaben_positionen_sichtbar INTEGER,
        aufgaben_positionen_ep VARCHAR,
        aufgaben_positionen_resultat INTEGER,
        aufgaben_positionen_wert VARCHAR,
        aufgaben_positionen_vorgabe VARCHAR,
        aufgaben_positionen_wert_einheit VARCHAR,
        aufgaben_positionen_vergleich INTEGER,
        aufgaben_position_ust VARCHAR,
        aufgaben_positionen_rechnung VARCHAR,
        aufgaben_positionen_speiseplandatum_von DATE,
        aufgaben_positionen_speiseplandatum_bis DATE
      );
    `,
    [ [ `aufgaben_positionen_id` , `aufgaben_positionen_id` ] ]

  ] ,
  [ 'saint_zusatzstoffe' ,
    `
     (
        zusatzstoff_id	INTEGER  NOT NULL ,	
        zusatzstoff_bezeichnung	VARCHAR NOT NULL ,
        zusatzstoff_typ	INTEGER  NOT NULL ,	
        zusatzstoff_beschreibung	VARCHAR NOT NULL ,
        zusatzstoffe_anzeige	VARCHAR NOT NULL
      ); 
    `
  ] ,*/
]

