/** Ionic-Styles */
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

/** Ionic / React */
import { useSQLite } from 'react-sqlite-hook';
import {
    IonApp,
    IonRouterOutlet,
    setupIonicReact,
    IonContent,
    IonToast,
    IonItem,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import React, { useState } from 'react';

/** Context */
import { DataContext } from './context/DataContext';
import AppContext from './context/AppContext';

/** Pages / Components */
import Kacheln from './pages/Kacheln';
import Login from './pages/Login';
import HeaderComponent from './components/Header';
import FooterComponent from './components/Footer';
import { DBInit } from './components/DBInit';
import { dbQuery, dbBufferWorker , exportDbBufferIn , exportDbBufferOut } from '@saint/sqlite-worker';
import { DataLoadError } from './components/DataLoadError';
import { GetResult, Preferences } from '@capacitor/preferences';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';

import { ENV_SETTINGS } from './ENV_SETTINGS' ;
import LieferantenArtikelUebersicht from './pages/LieferantenArtikelUebersicht';


// Singleton SQLite Hook
export let sqlite: any;
// Existing Connections Store
export let existingConn: any;



setupIonicReact();

/** CapacitorStorage / Preferences auswerten */
  


    
const App: React.FC = () => {
    async function reset_by_preference_if_exist ( fn_setter:Function , str_prefname:string , str_default:string ) 
    {
        // console.log('Preferences fired')
      Preferences
        .get(
          {
            key: str_prefname
          }
        )
        .then(
          ( obj_result:GetResult ) =>
            {
              if( obj_result.value !== null )
                {
                  fn_setter( obj_result.value ) ;
                }
              else
                {
                  Preferences
                    .set(
                      {
                        key: str_prefname ,
                        value: str_default
                      }
                    )
                    .then(
                      () => {}
                    )
                }
            }
        )
    }

    function loginReset(){
        Preferences.remove({key:ENV_SETTINGS.NS + '.token'})
           setAppLoggedIn(false)
        }
    const handlers = {
        loginReset: () => {
            Preferences.remove({key:ENV_SETTINGS.NS + '.token'})
               setAppLoggedIn(false)
            }
    }
    const [existConn, setExistConn] = useState(false);
    existingConn = { existConn: existConn, setExistConn: setExistConn };
    const {
        echo,
        getPlatform,
        createConnection,
        closeConnection,
        retrieveConnection,
        retrieveAllConnections,
        closeAllConnections,
        addUpgradeStatement,
        importFromJson,
        isJsonValid,
        copyFromAssets,
        isAvailable,
    } = useSQLite();
    sqlite = {
        echo: echo,
        getPlatform: getPlatform,
        createConnection: createConnection,
        closeConnection: closeConnection,
        retrieveConnection: retrieveConnection,
        retrieveAllConnections: retrieveAllConnections,
        closeAllConnections: closeAllConnections,
        addUpgradeStatement: addUpgradeStatement,
        importFromJson: importFromJson,
        isJsonValid: isJsonValid,
        copyFromAssets: copyFromAssets,
        isAvailable: isAvailable,
    };

    const [name, setName] = useState('');
    const [appToken, setAppToken] = useState();
    const [appUser, setAppUser] = useState( '' );
    const [server, setServer] = useState( ENV_SETTINGS.SERVER );
    const [domain, setDomain] = useState( ENV_SETTINGS.DOMAIN );
    const [appLoggedIn, setAppLoggedIn] = useState(false);
    const [appKunden, setAppKunden] = useState({values:[]});
    const [appChosenKundenId, setAppChosenKundenId] = useState();
    

    const [appChosenApp, setAppChosenApp] = useState('');

    
    const [appToastShow, setAppToastShow] = useState(false);
    const [appToastMessage, setAppToastMessage] = useState();
    const [appToastColor, setAppToastColor] = useState('success');
    const [DBInited, setDBInited] = useState(false);
    const [ DBInit_useTTL , setDBInit_useTTL ] = useState( true );
    const [articleSQLResult, setArticleSQLResult] = useState('');
    
    const [bestellungKostfrei, setBestellungKostfrei] = useState( []);

    
    function formatCurrency(key: any) {
        return new Intl.NumberFormat('de-DE', {
            maximumFractionDigits: 3,
            style: 'currency',
            currency: 'EUR',
        }).format(key);
    }

    function formatFloat(key: any) {
        return new Intl.NumberFormat('de-DE', {
            maximumFractionDigits: 3,
        }).format(key);
    }

    let dataContextValue = {
        name: name,
        nameChange: setName,
        DBInited: DBInited,
        DBInitedChange: setDBInited,
        DBInit_useTTL: DBInit_useTTL,
        DBInit_useTTLChange: setDBInit_useTTL,

        appToken: appToken,
        appTokenChange: setAppToken,
        appUser: appUser,
        appUserChange: setAppUser,
        server: server,
        serverChange: setServer,
        domain: domain,
        domainChange: setDomain,
        appLoggedIn: appLoggedIn,
        appLoggedInChange: setAppLoggedIn,

        appChosenApp: appChosenApp,
        appChosenAppChange: setAppChosenApp,

        appKunden:appKunden,
        appKundenChange:setAppKunden,
        appChosenKundenId:appChosenKundenId,
        appChosenKundenIdChange:setAppChosenKundenId,
        appToastShow: appToastShow,
        appToastShowChange: setAppToastShow,
        appToastMessage: appToastMessage,
        appToastMessageChange: setAppToastMessage,
        appToastColor: appToastColor,
        appToastColorChange: setAppToastColor,

        formatCurrency: formatCurrency,
        formatFloat: formatFloat,
        dbQuery:dbQuery,
        dbBufferWorker:dbBufferWorker,
        exportDbBufferIn: exportDbBufferIn ,
        exportDbBufferOut:exportDbBufferOut,
        loginReset:loginReset ,
        handlers:handlers,
    };

useEnhancedEffect(()=>{
    reset_by_preference_if_exist( setDomain , ENV_SETTINGS.NS + '.domain' , domain ) ;
    reset_by_preference_if_exist( setServer , ENV_SETTINGS.NS + '.server' , server ) ;
},[])
    return (
        <>
            <DataContext.Provider value={dataContextValue}>
                <DataLoadError/>
                <AppContext />
                <IonApp>
                    {appLoggedIn === false ? (
                        <Login />
                    ) : (
                        <>
                            {
                             ! DBInited  ? (
                                <DBInit />
                            ) : (
                                <>
                                    <IonReactRouter>
                                        <HeaderComponent>Loading...</HeaderComponent>
                                        <IonContent>
                                            <IonRouterOutlet>
                                                <Route
                                                    exact
                                                    path="/">
                                                    <Kacheln />
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/ArtikelUebersicht">
                                                    <LieferantenArtikelUebersicht />
                                                </Route>
                                            </IonRouterOutlet>
                                        </IonContent>
                                        <FooterComponent>Loading...</FooterComponent>
                                    </IonReactRouter>
                                </>
                            )}
                            <IonToast
                                duration = { 3000 }
                                isOpen={appToastShow}
                                onDidDismiss={() => setAppToastShow(false)}
                                message={appToastMessage}
                                position="bottom"
                                color={appToastColor}
                                buttons={[
                                    {
                                        text: 'schliessen',
                                        role: 'cancel',
                                        handler: () => {
                                            setAppToastShow(false);
                                        },
                                    },
                                ]}
                            />
                            
                        </>
                    )}
                </IonApp>
            </DataContext.Provider>
        </>
    );
};

export default App;
