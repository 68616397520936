import { IonItem, IonIcon } from '@ionic/react';
import { useEffect, useState } from 'react';
import { exportBEBufferEmpty } from '@saint/fetch-worker';
import { exportDBBufferEmpty } from '@saint/sqlite-worker';
import { cloudUploadOutline, serverOutline } from 'ionicons/icons';

const BufferActivityDisplay: any = () => {

  const [timer, setTimer] = useState(0);
  const [SQLiteEmpty, setSQLiteEmpty] = useState(false);
  const [BEEmpty, setBEEmpty] = useState(false);
    
  useEffect(
    function timer() {
      setSQLiteEmpty( exportDBBufferEmpty() ) ;
      setBEEmpty( exportBEBufferEmpty() ) ;
      setTimeout( () => { setTimer( Math.random ) ; } , 500 ) ;
    } ,
    [ timer ]
  ) ;

  return (
    <>
      <IonItem
        lines="none"
        slot="start"
      >
        { BEEmpty ? null : <IonIcon icon = { cloudUploadOutline } color = "warning" /> }
      </IonItem> 
      <IonItem
        lines="none"
        slot="start"
      >
        { SQLiteEmpty ? null : <IonIcon icon = { serverOutline } /> }
      </IonItem>
    </>
  );
}

export default BufferActivityDisplay ;
