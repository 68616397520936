import { IonGrid, IonRow, IonCol, IonCard, IonContent, IonList, IonLabel, IonButton, IonListHeader, IonHeader, IonItem,IonIcon, IonPage, IonTitle, IonToolbar, IonCardContent, IonChip, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import {DataContext} from '../context/DataContext';
import { listOutline } from 'ionicons/icons';
import { dbQuery } from '@saint/sqlite-worker';




function KachelLieferantenArtikel(props:any) {

    const [localLieferantenArtikelHTML, setLocalLieferantenArtikelHTML] = useState(<IonItem>Lade Daten...</IonItem>)
    const [localLieferantenArtikel, setLocalLieferantenArtikel] = useState([])
const {
    appChosenKundenId,
    appChosenAppChange
} = useContext(DataContext);

useEffect( () => { 
   setLocalLieferantenArtikelHTML(
    <>
    <IonItem>
        <h1>Letzte Änderungen</h1>
    </IonItem>
    <IonList>
        {
            localLieferantenArtikel.values.length>0
                ?
                    //@ts-ignore
                    localLieferantenArtikel.values.map((artikel: any) => {
                        return (
                            <IonItem>
                                <IonLabel slot="start">{artikel.artikel_bezeichnung}</IonLabel>
                                <IonLabel slot="end">{new Date(artikel.artikel_last_update).toLocaleDateString('de-DE',{ weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</IonLabel>
                            </IonItem>
                            );
                    })
                :
                    <IonItem>
                        Keine Änderungen
                    </IonItem>
        
        }
    </IonList>
    </>
    )
    
},[localLieferantenArtikel])

useEffect( () => { 
    if(appChosenKundenId){
        dbQuery(`SELECT * FROM saint_artikel WHERE artikel_id LIKE '`+appChosenKundenId+`-%' ORDER BY artikel_last_update DESC  LIMIT 0,10`, setLocalLieferantenArtikel)
    }
    
},[appChosenKundenId])

useEffect( () => { 
console.log('localLieferantenArtikel',localLieferantenArtikel.values)




},[localLieferantenArtikel])

    return (
        // <IonPage>
        
        <IonCard key="main" class="item-trans"  style={{ minHeight: '400px', maxHeight:'400px',color: `#ffffff`, backgroundColor: `#AAB6B2`}}>
            <IonCardHeader >
                <IonCardHeader style={{color: `#000000`, backgroundColor: `#ffffff`,fontSize:"2em"}}><IonIcon  icon={listOutline} ></IonIcon> Artikel</IonCardHeader>
                <IonCardSubtitle><IonButton size="small" routerLink ={'ArtikelUebersicht'} onClick={()=>appChosenAppChange(<><IonIcon  icon={listOutline} ></IonIcon>Artikelübersicht</>)} expand="block">Artikelliste öffnen</IonButton></IonCardSubtitle>
            </IonCardHeader>
            

      
        <IonCardContent style={{backgroundColor: `#ffffff`, minHeight:'220px',maxHeight:'220px', overflowY:'auto'}}>
        Letzte Änderungen:
            <IonList>
                {localLieferantenArtikelHTML}
            </IonList>
        
        </IonCardContent> 
        </IonCard>
     
    )
};

export default KachelLieferantenArtikel;
