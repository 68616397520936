import SaintLogo from '../assets/saint-logo.png'
import { IonHeader, IonItem, IonToolbar } from '@ionic/react';

import FabButton from '../components/FabButton';

import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

const HeaderComponent:any = () => {
  
  const {
    appChosenBetrieb,
    appChosenApp
  } = useContext(DataContext);


  return (
    <IonHeader>
    <IonToolbar>
    <div style={{display: 'flex', justifyContent:'space-between'}}>
        <IonItem lines="none" routerLink="/"><img style={{height: '50px'}} src={SaintLogo} alt="SAINT"/></IonItem>
        <IonItem lines="none" >{appChosenApp}</IonItem>
        <IonItem lines="none"><div><FabButton/></div></IonItem>
    </div>        
  </IonToolbar>
  </IonHeader> 
  );
};

export default HeaderComponent;
