import { useContext } from 'react';
import { DataContext } from './DataContext';
import { useEffect } from 'react';
import { Preferences } from '@capacitor/preferences';
import jwt_decode from 'jwt-decode';
import { dbQuery } from '@saint/sqlite-worker';
import { init_bworker } from '@saint/fetch-worker';

const AppData: any = () => {

    const {
       
        appChosenKundeId,
        appChosenKundeIdChange,
        appKunden,
        appKundenChange,
        appKundenReloadChange,
        appToken,
        appTokenChange,
        appLoggedInChange,
        DBInited
    } = useContext(DataContext);

    useEffect(()=>{
        if(DBInited===true){
          init_bworker( true ) ;
          dbQuery(`SELECT * FROM saint_user_lieferant ORDER BY user_lieferant_kunde ASC`,appKundenChange)
        }
    },[DBInited,appChosenKundeId])

    /* useEffect(() => {
        const writeDomain = async () => {
            await Preferences.set({ key: ENV_SETTINGS.NS + '.domain', value: domain });
            // console.log(ENV_SETTINGS.NS + '.domain geschrieben', domain);
        };
        writeDomain();
    }, [domain]); */

    return null;
};

export default AppData;
