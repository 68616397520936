import React from 'react';
import { IonSpinner } from '@ionic/react';
import { DataContext } from '../context/DataContext';
import { useContext } from 'react';


import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { Logout } from './Logout'
import { AppReset } from './AppReset';

function FabButton() {
       
        const {
                appKunden,
                appChosenKundenId,
                appChosenKundenIdChange
        } = useContext(DataContext);


        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
                setAnchorEl(null);
        };

        return (
                <>
                        <Button
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                color={appChosenKundenId === null ? 'primary' : 'primary'}
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon />}
                        >
                                {
                                        ! appKunden.values.length > 0
                                                ?
                                                        <><IonSpinner name = 'bubbles' style = { { color: 'white' } }/>&nbsp;lade Kunden ...</>
                                                :
                                                        appChosenKundenId
                                                                ?
                                                                appChosenKundenId
                                                                :
                                                                        'Kunden wählen'
                                }
                        </Button>

                        <Menu
                                id="long-menu"
                                MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                elevation={10}>
                                {appKunden.values &&
                                        appKunden.values.map((e) => {
                                                return (
                                                        
                                                        <div
                                                                key={ 'betrieb_' + e.user_lieferant_kunde }
                                                                onClick={() => {
                                                                        appChosenKundenIdChange(e.user_lieferant_kunde);
                                                                        //appChosenBetriebIdChange(e.betriebe_id);
                                                                }}>
                                                                        <div>
                                                                <MenuItem
                                                                        disableRipple
                                                                        onClick={handleClose}>
                                                                        {e.user_lieferant_kunde}
                                                                </MenuItem>
                                                                </div>
                                                        </div>
                                                );
                                        })}

                                <Logout/>
                                <AppReset/>
                        </Menu>
                </>
        );
}

export default FabButton;
