import { useContext, useEffect , useState } from 'react';
import { DataContext } from '../context/DataContext';
import axios from 'axios';
import { IonButton, IonCard, IonCardContent, IonModal, IonSpinner } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import { AppReset } from '../components/AppReset';
import { arrBackends } from '../settings/backends';
import { arrDbTables } from '../settings/arrDbTables';
import { dbQuery , exportDbBufferIn , exportDbBufferOut } from '@saint/sqlite-worker'
import { ENV_SETTINGS } from './../ENV_SETTINGS' ;

const strComponentName = 'DBInit' ;
let intBackendsChecked = 0 ;
let intBackendsToFetch = 0 ;
let intFetchesDone = 0 ;

const getFetchesDone = () =>
  {
    return intFetchesDone ;
  }

const getIntBackendsToFetch = () =>
  {
    return intBackendsToFetch ;
  }

const getBackendsChecked = () =>
  {
    return intBackendsChecked ;
  }

const resetCounters = () =>
  {
    intBackendsChecked = 0 ;
    intBackendsToFetch = 0 ;
    intFetchesDone = 0 ;        
  }

export const DBInit: any = () => {
    const {
      DBInited,
      DBInitedChange ,
      DBInit_useTTL ,
      appToken,
      server
  } = useContext(DataContext);

  let stateFatalError = false ;

  const [ dataLoadErrorShow , setDataLoadErrorShow ] = useState( false ) ;
  const [ dataLoadErrorMsg , setDataLoadErrorMsg ] = useState( <p>'Fehler beim Laden der Daten - bitte neu starten'</p> ) ;
  const [ dataLoadMsgShow , setDataLoadMsgShow ] = useState( true ) ;
  const [ readyToGo , setReadyToGo ] = useState( false ) ;
  const [ timer , setTimer ] = useState( 0 ) ;
  const [ strLoadStatusMsg , setStrLoadStatusMsg ] = useState( 'Daten werden geladen ...' ) ;

/** Funktionen */
  /** Restart als Option im Fehlerfall */
    const restart = async () => {
      await Preferences.clear() ;
      window.location.href = '/' ;
    };

  /** Logout als Option im Fehlerfall */
    const logout = async () => {
    await Preferences.remove( { key: ENV_SETTINGS.NS + '.token' } ) ;
    window.location.href = '/' ;
};

/** doInitTables
 *  - Tabellen initialisieren
*/
const doInitTables = async () =>
  {
    for( let i = 0 ; i < arrDbTables.length ; i++ ) {
      if( ! stateFatalError ) {
        dbQuery( 'CREATE TABLE IF NOT EXISTS ' + arrDbTables[ i ][ 0 ] + arrDbTables[ i ][ 1 ] ) ;
      }
    }
  }

  async function fetch_and_write_to_db(url: any, tabelle: any) {
    let arrDbTables_id =  arrDbTables.findIndex( ( entry:any ) => entry[ 0 ] === tabelle ) ;
    var config = {
      method: 'post',
      url: server + `/funktionen/api2/${url}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${appToken}`,
      },
    };
    await axios(config)
      .then(
        async function( response: { data: { fieldnames: []; data: [] } } ) {
          if( Array.isArray( response.data.data ) ) {
            dbQuery( 'DELETE FROM ' + tabelle ) ;
            if( arrDbTables[ arrDbTables_id ][ 2 ] ) {
              arrDbTables[ arrDbTables_id ][ 2 ]
                .forEach(
                  ( idx:any ) =>
                    {
                      dbQuery( 'DROP INDEX IF EXISTS ' + idx[ 0 ] ) ;
                    }
                )
            }
          }
          await Preferences.set( { key: ENV_SETTINGS.NS + '.fetch.' + tabelle , value: new Date().valueOf().toString() } ) ;
          let fieldnames = response.data.fieldnames;
          let data = response.data.data;
          let ins = 'INSERT INTO ' + tabelle + ' ( ' + fieldnames + ' ) VALUES ' ;
          let values = '';
          data
            .forEach(
              (row: any, rowid: number) => {
                values += '(';
                for (let i = 0; i < fieldnames.length; i++) {
                  values += "'" + row[i].replace("'", ' ') + "'";
                  if (i + 1 !== fieldnames.length) {
                    values += ',';
                  }
                }
                values += ')';
                if (rowid % 10000 === 0 || (rowid + 1) === data.length) {
                  dbQuery(ins + values + ';')
                  values = '';
                } else {
                  values += ',';
                }
              }
            )
          if( arrDbTables[ arrDbTables_id ][ 2 ] ) {
            arrDbTables[ arrDbTables_id ][ 2 ]
              .forEach(
                ( idx:any ) =>
                  {
                    dbQuery( 'CREATE INDEX IF NOT EXISTS ' + idx[ 0 ] + ' ON ' + arrDbTables[ arrDbTables_id ][ 0 ] + ' (' + idx[ 1 ] + ')' ) ;
                  }
              )
          }
          intFetchesDone += 1 ;
        }
      )
      .catch(
        function(error: any ) {
          stateFatalError = true ;
          let strErrUrl , strErrMsg ;
          if( error.response && error.response.statusText !== '' ) {
            strErrUrl = error.response.config.url ;
            strErrMsg = error.response.statusText ;
          } else {
            if( error.config ) {
              strErrUrl = error.config.url;
            } else {
              strErrUrl = url;
            }
            if( error.code ) {
              strErrMsg = error.code ;
            } else {
              strErrMsg = String( error ) ;
            }
          }
          setDataLoadErrorMsg(
            strErrMsg === 'Token expired'
              ?
                <section>
                  <h1>
                    Anmeldung abgelaufen
                  </h1>
                  <p>
                    Ihre Anmeldung ist abgelaufen ({ strErrMsg }). Sie müssen sich neu anmelden.
                  </p>
                </section>
              :
                <section>
                  <h1>
                    { strComponentName }: Fehler Fetch (Axios)
                  </h1>
                  <ul>
                    <li>
                      { strErrUrl }
                      <ul>
                        <li>
                          { strErrMsg}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
          );
          setDataLoadErrorShow(true);
        }
      );
  }

  useEffect( function init()
    {
      exportDbBufferOut( 'reset' ) ;
      exportDbBufferIn( 'reset' ) ;
      setStrLoadStatusMsg( 'Daten werden geladen ...' ) ;
      doInitTables()
        .then(
          () =>
            {
              arrBackends
                .forEach(
                  async ( entry:any ) => {
                    intBackendsChecked += 1 ;
                    if( DBInit_useTTL === false || ( Number( ( await Preferences.get( { key: ENV_SETTINGS.NS + '.fetch.' + entry[ 1 ] } ) ).value ) + entry[ 2 ] )  < new Date().valueOf() ) {
                      intBackendsToFetch += 1 ;
                      fetch_and_write_to_db( entry[ 0 ] , entry[ 1 ] )
                    }
                  }
                )
            }
        )
    }
    ,
    []
  ) ;

  useEffect( function timer()
    {
      if(DBInited===false){
        if( intBackendsChecked === arrBackends.length && intFetchesDone === intBackendsToFetch && exportDbBufferOut() >= exportDbBufferIn() ) {
          resetCounters() ;
          setDataLoadMsgShow(false)
          setReadyToGo( true ) ;
          exportDbBufferOut( 'reset' ) ;
          exportDbBufferIn( 'reset' ) ;
        } else {
          setTimeout( () => { setTimer(Math.random); } , 1000 )
        }
      }
    } ,
    [timer]
  ) ;

  useEffect(
    () =>
      {
        if( readyToGo ) {
          DBInitedChange( true) ;   
        }
      } ,
    [ readyToGo , DBInitedChange ]
  ) ;

  return (
    <>
     <IonModal backdropDismiss = { false } canDismiss = { true }
        isOpen = { ! stateFatalError && dataLoadMsgShow }
      >
        <IonCard
          style = {
            {
              height: '100%'
            }
          }
        >
          <IonCardContent>
            {
              intBackendsChecked === arrBackends.length && intFetchesDone === intBackendsToFetch && exportDbBufferOut() >= exportDbBufferIn()
                ?
                  <IonButton
                    onClick = { () => { setDataLoadMsgShow( false ) ; setReadyToGo( true ) } }
                  >
                    Weiter
                  </IonButton>
                :
                  <IonSpinner name = 'bubbles' />
            }
            <p>
              { strLoadStatusMsg }
            </p>
            <p>
              { getBackendsChecked() } von { arrBackends.length } Quellen geprüft
            </p>
            <p>
              { getFetchesDone() } von { getIntBackendsToFetch() } erforderlichen Aktualisierungen abgerufen
            </p>
            <p>
              { exportDbBufferOut() } von { exportDbBufferIn() } Speicherungsschritten ausgeführt
            </p>
            <p>
            <AppReset/>

            </p>
          </IonCardContent>
          {
            stateFatalError
              ?
                <IonCardContent>
                  { dataLoadErrorMsg }
                </IonCardContent>
              :
                null
          }
        </IonCard>
      </IonModal>
      <IonModal key="dataLoadError"
        isOpen = { dataLoadErrorShow }
        backdropDismiss = { true }
      >
        <IonCard>
          <IonCardContent
            style = {
              {
                fontSize: '1.2em' ,
                fontWeight: 'bold'
              }
            }
          >
            { dataLoadErrorMsg }
          </IonCardContent>
          <IonCardContent>
            <IonButton
              onClick = { () => logout() }
            >
              Wieder anmelden (am selben Server)
            </IonButton>
            <IonButton
              onClick = { () => restart() }
            >
              Neu anmelden (an neuem Server)
            </IonButton>
            <IonButton
              onClick = { () => setDataLoadErrorShow( false ) }
            >
              Schließen
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonModal>
    </>
  ) ;
}
