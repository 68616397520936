import { IonButton, IonFooter, IonItem, IonToolbar, IonInput } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import { bworkerQuery } from '@saint/fetch-worker';
import { dbQuery } from '@saint/sqlite-worker';
import BufferActivityDisplay from './BufferActivityDisplay';
import DataReload from './DataReload';

const FooterComponent: any = () => {

    const {
        handlers
    } = useContext(DataContext);

    const [sqlSearch, setSqlSearch] = useState('');
    const [MySqlResult, setMySqlResult] = useState('');

    function changeSearch(e: any) {
        setSqlSearch(e.target.value);
    }

    useEffect(() => {
        // console.log('Footer.tsx: UseEffect MySqlResult triggered');
        if( MySqlResult !== '' ) {
          console.log('MySqlResult:', MySqlResult);
        }
    }, [MySqlResult]);
   
    return (
        <IonFooter>
            <IonToolbar>
                <DataReload/>
                <BufferActivityDisplay/>
                    {
                        process.env.NODE_ENV !== 'development'
                            ?
                                null
                            :
                                <>
                                    <IonItem lines="none" slot="end">
                                        <IonButton
                                            onClick = { () => 
                                                {
                                                    handlers.loginReset()
                                                }
                                            }
                                        >
                                            loginReset
                                        </IonButton>
                                    </IonItem>
                                    <IonItem
                                        lines="none"
                                        slot = "end"
                                        style = { { border: 'thin solid orange' } }
                                    >
                                        Backend-Worker:
                                        <IonButton
                                            onClick={
                                                () => bworkerQuery( { url: 'URL_https://adasdasdasd.com/' , method: 'GET' , handlers } )
                                            }
                                        >
                                            1 C 2 A
                                        </IonButton>
                                        <IonButton
                                            onClick={
                                                () => bworkerQuery( { url: 'URL' , method: 'GET' , data: { data1: 'DATA1' , data2: 'DATA2' } , target: () => {} , reload: [ 'RELOAD1' , 'RELOAD2' ] , handlers } )
                                            }
                                        >
                                            1 C 5 A
                                        </IonButton>
                                        <IonButton
                                            onClick={
                                                () => {
                                                    bworkerQuery( { url: 'URL1' , method: 'GET' , handlers } ) ;
                                                    bworkerQuery( { url: 'URL2' , method: 'POST' , handlers } ) ;
                                                    bworkerQuery( { url: 'https://devel-rw.saint-online.de/funktionen/api2/artikel/bworker_test.php?status=403&status2=200' , method: 'DELETE' , handlers: handlers } ) ;
                                                    bworkerQuery( { url: 'https://devel-rw.saint-online.de/funktionen/api2/artikel/bworker_test.php?status=200&status2=300' , method: 'DELETE' , handlers: handlers } ) ;
                                                    bworkerQuery( { url: 'https://web1.saint-online.de' , method: 'DELETE' , handlers } ) ;
                                                    bworkerQuery( { url: 'URL5' , method: 'DELETE' , handlers } ) ;
                                                    bworkerQuery( { url: 'URL6' , method: 'DELETE' , handlers } ) ;
                                                    bworkerQuery( { url: 'URL7' , method: 'DELETE' , handlers } ) ;
                                                    bworkerQuery( { url: 'URL8' , method: 'DELETE' , handlers } ) ;
                                                }
                                            }
                                        >
                                            3 C 2 A
                                        </IonButton>
                                    </IonItem>
                                    <IonItem lines="none" slot="end">
                                        <IonInput
                                            placeholder="SELECT * FROM ...."
                                            value={sqlSearch}
                                            onIonChange={(e: any) => changeSearch(e)}
                                            onKeyUp = { ( e ) => { if( e.key === 'Enter' ) { dbQuery(sqlSearch,setMySqlResult) ; } } }
                                        ></IonInput>
                                        <IonButton
                                            size="small"
                                            color="danger"
                                            onClick={() => {
                                                dbQuery(sqlSearch,setMySqlResult)
                                            }}>
                                            SQL
                                        </IonButton>
                                        
                                    </IonItem>
                                </>
                    }
            </IonToolbar>
        </IonFooter>
    );
};

export default FooterComponent;
