import { DataContext } from '../context/DataContext';
import { useContext } from 'react';

import { IonIcon, IonItem } from '@ionic/react';

import { Preferences } from '@capacitor/preferences';
import { logOut } from 'ionicons/icons';

import { ENV_SETTINGS } from './../ENV_SETTINGS' ;

export const Logout: any = () => {
  const { } = useContext(DataContext);

  const logout = async () => {
    await Preferences.remove( { key: ENV_SETTINGS.NS + '.token' } ) ;
    window.location.href = '/' ;
  };

  return (
    <IonItem
      lines="none"
      button
      onClick={logout}>
      Log Out
      <IonIcon
        size="large"
        icon={ logOut }
      />
    </IonItem>
  );
}
