/** arrBackends: abzufragende Backends
 *    - pro Backend ein Array mit
 *      - Col 1: API-Endpoint
 *      - Col 2: Tabellenname
 *      - Col 3: Time to live (TTL) in Millisekunden
 *    - Bsp.:
 *      - [ 'artikel/get_betriebe_neu.php' , 'saint_betriebe' , 28800000 ]
 */

import { ENV_SETTINGS } from './../ENV_SETTINGS' ;

export const arrBackends =
  [
    [ ENV_SETTINGS.NS + '/get_artikel.php', 'saint_artikel' , 0 ] ,
    [ ENV_SETTINGS.NS + '/get_user_lieferant.php', 'saint_user_lieferant' , 0 ] ,
    [ ENV_SETTINGS.NS + '/get_preise.php', 'saint_preise' , 0 ] ,
   /* [ ENV_SETTINGS.NS + '/get_aufgaben_positionen.php', 'saint_aufgaben_positionen' , 28800000 ] ,*/
   [ ENV_SETTINGS.NS + '/get_zusatzstoffe.php', 'saint_zusatzstoffe' , 0 ] ,
   [ ENV_SETTINGS.NS + '/get_zusatzstoffe_artikel.php', 'saint_zusatzstoffe_artikel' , 0 ] ,
  ]
