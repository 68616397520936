import { DataContext } from '../context/DataContext';
import { useContext } from 'react';

import { IonIcon, IonItem } from '@ionic/react';

import { Preferences } from '@capacitor/preferences';
import { trashOutline } from 'ionicons/icons';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { ENV_SETTINGS } from '../ENV_SETTINGS' ;



export const AppReset: any = () => {
  const { } = useContext(DataContext);
  const sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);

  const app_reset = async () => {
    console.log("setze app zurück")
    await Preferences.clear() ;

    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection( ENV_SETTINGS.NS ,false)).result;
    var db;
   if (ret.result && isConn) {
    db = await sqlite.retrieveConnection( ENV_SETTINGS.NS ,false);
    } else {
    db = await sqlite.createConnection( ENV_SETTINGS.NS , false, 'no-encryption', 1 , false );
    }

    const isDb = (await db.isExists()).result;
    if ( ! isDb ) {
      console.error('DBInit: DB nicht connected');
    }
    await db.open();
    const isDbOpen = (await db.isDBOpen()).result;
    if ( ! isDbOpen ) {
      console.error('DBInit: DB nicht open');
    }
    let returnDelete= await CapacitorSQLite.deleteDatabase({database:ENV_SETTINGS.NS})

    console.log('returnDelete',returnDelete)
    alert('Datenbank wurde zurückgesetzt. Bitte warten!')

    setTimeout(function(){
  }, 10000);

    window.location.href = '/' ;
  };

  return (
    <IonItem
      color="warning"
      lines="none"
      button
      onClick={app_reset}
      style =
        {
          {
            fontSize: '0.9em' ,
            marginTop: '1em'
          }
        }
    >
        App zurücksetzen
      <IonIcon
        size="medium"
        icon={ trashOutline }
      />
    </IonItem>
  );
}
