
import SaintLogo from '../assets/saint-logo.png'

import {
    IonContent,
    IonCard,
    IonCardContent,
    IonButton,
    IonToast,
    IonHeader,
    IonSegment,
    IonPage,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonCardHeader,
    IonCardTitle,
} from '@ionic/react';
import { useContext } from 'react';
import { DataContext } from '../context/DataContext';

import { useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { AppReset } from '../components/AppReset';

import { ENV_SETTINGS } from './../ENV_SETTINGS' ;

function ab2str(buf:any) {
    //@ts-ignore
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }
  
  function str2ab(str:string) {
    var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i=0, strLen=str.length; i<strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return bufView
  }

async function sha512(str:string) {
    let ab=str2ab(str);


    let cryptResult=await crypto.subtle.digest("SHA-512",ab )

    let retString=ab2str(cryptResult)
    return retString
    
  }
  

const Login: React.FC = () => {
    const { appTokenChange, appUserChange , appLoggedInChange, DBInitedChange , domainChange, serverChange, server, domain } = useContext(DataContext);

    const [segment, setSegment] = useState<string>('login');
    const [loginUser, setLoginUser] = useState<string >("");
    const [loginPass, setLoginPass] = useState<string >("");
    const [loginToast, setLoginToast] = useState(false);
    const [loginToastMessage, setLoginToastMessage] = useState<string>();

    function fetchlogin() {
        var data = {
            usr: loginUser,
            pwd: loginPass,
            domain: domain,
        };

        var config = {
            method: 'post',
            url: server + '/funktionen/api2/' + ENV_SETTINGS.NS + '/login.php',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(async function (response) {
                // console.log(JSON.stringify(response.data));

                if (response.data) {
                    let storagedata = {
                        key: ENV_SETTINGS.NS + '.token',
                        value: response.data.token,
                    };
                    // console.log('storagedata', storagedata);

                    await Preferences.set({ key: ENV_SETTINGS.NS + '.domain', value: domain });
                    await Preferences.set({ key: ENV_SETTINGS.NS + '.server', value: server });

                    Preferences.set(storagedata);
                    appTokenChange(response.data.token);
                    // let pwHash = await sha512(loginPass)
                    // Preferences.set({key:ENV_SETTINGS.NS + '.pwhash',value:pwHash})
                    // Preferences.set({key:ENV_SETTINGS.NS + '.user',value:loginUser})
                    appUserChange( loginUser ) ;

                    if (response.data.status === 200) {
                        setLoginToast(false);
                        appLoggedInChange(true);
                        setLoginToastMessage('Login erfolgreich');
                        // window.location.reload();
                        // console.log("hugo")
                    }
                }
            })
            .finally(function () {
                //setLoginToast(false);
            })

            .catch(async function (error) {
                if (error.response) {
                    if (error.response.data.status === 401) {
                        setLoginToast(true);
                        setLoginToastMessage('Ungültige Zugangsdaten');
                    }
                    if (error.response.data.status === 404) {
                        setLoginToast(true);
                        setLoginToastMessage('Bitte geben Sie eine gültige Serveradresse an.');
                    }

                    // console.log(error.response.data.status);
                }
                // if(error.message === "Network Error"){
                //     console.warn("Netzwerkfehler",error.message)
                    
                //     let cachedUser = (await Preferences.get({key:ENV_SETTINGS.NS + ".user"})).value
                //     if(loginUser === cachedUser){
                //         let cachedPwHash=(await Preferences.get({key:ENV_SETTINGS.NS + ".pwhash"})).value
                //         if(cachedPwHash === await sha512(loginPass)){
                //             await DBInitedChange( true ) ;
                //             await appLoggedInChange(true);
                //             setLoginToastMessage('Login erfolgreich');
                //         }
                //     }
                    
                // }
                console.log('err', error);
            });
    }

    async function anmelden() {
        setLoginToast(false);
        // console.log('Anmeldebutton geklickt');

        if (loginUser === undefined || loginUser === '' || loginUser === null) {
            console.log('Bitte geben Sie ein Passwort ein');

            setLoginToastMessage('Bitte geben Sie ein Passwort ein');
            setLoginToast(true);
        } else if (loginUser === undefined || loginUser === '' || loginUser === null) {
            console.log('Bitte geben Sie einen Benutzernamen ein');

            setLoginToastMessage('Bitte geben Sie einen Benutzernamen ein');
            setLoginToast(true);
        } else if (server === undefined || server === '' || server === null) {
            console.log('Bitte geben Sie einen gültigen Server ein');

            setLoginToastMessage('Bitte geben Sie einen gültigen Server ein');
            setLoginToast(true);
        } else {
            fetchlogin();
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    
                    
                    <IonItem slot="start" lines="none" routerLink="/"><img style={{height: '50px'}} src={SaintLogo} alt="SAINT"/></IonItem>
        <IonItem slot="end">Lieferantenportal</IonItem>

                   
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonCard>
                   <IonCardHeader>
                    <IonCardTitle>
                <IonSegment value={segment}>
                    <IonSegmentButton
                        value="login"
                        onClick={() => setSegment('login')}>
                        <IonLabel>Login</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                        value="settings"
                        onClick={() => setSegment('settings')}>
                        <IonLabel>Einstellungen</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                </IonCardTitle>
                   </IonCardHeader>
                <IonCardContent>
                {segment && segment === 'login' ? (
                    <>
                        <IonItem>
                            <IonLabel position="floating">Benutzername</IonLabel>
                            <IonInput
                                placeholder="Benutzername"
                                // @ts-ignore
                                onIonChange={(e) => setLoginUser(e.target.value)}
                                value={loginUser}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Passwort</IonLabel>
                            <IonInput
                                placeholder="Passwort"
                                type="password"
                                // @ts-ignore
                                onIonChange={(e) => setLoginPass(e.target.value)}
                                value={loginPass}></IonInput>
                        </IonItem>
                        <IonButton
                            expand="full"
                            onClick={() => anmelden()}>
                            Anmelden
                        </IonButton>
                    </>
                ) : segment && segment === 'settings' ? (
                    <>
                        <IonItem>
                            <IonLabel position="floating">Server</IonLabel>
                            <IonInput
                                placeholder="Server"
                                onIonChange={(e) => serverChange(e.target.value)}
                                value={server}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Domäne</IonLabel>
                            <IonInput
                                placeholder="Domäne"
                                onIonChange={(e) => domainChange(e.target.value)}
                                value={domain}></IonInput>
                        </IonItem>
                        <AppReset/>
                        <IonItem>
                            <IonLabel>Version 1.0.2.3799</IonLabel>
                        </IonItem>
                        
                    </>
                ) : (
                    ''
                )}
                </IonCardContent>
</IonCard>
                <IonToast
                    isOpen={loginToast}
                    onDidDismiss={() => setLoginToast(false)}
                    message={loginToastMessage}
                    position="bottom"
                    color={'warning'}
                    buttons={[
                        {
                            text: 'schliessen',
                            role: 'cancel',
                            handler: () => {
                                setLoginToast(false);
                            },
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default Login;
