import { IonItem, IonIcon } from '@ionic/react';
import { useContext , useState } from 'react';
import { DataContext } from '../context/DataContext';
import { reloadOutline } from 'ionicons/icons';

const DataReload: any = () => {

  const {
    DBInitedChange ,
    DBInit_useTTLChange
  } = useContext(DataContext);

  const [ iconColor , setIconColor ] = useState( '' ) ;

  return (
    <>
      <IonItem
        lines="none"
        slot="start"
      >
        <IonIcon
          size="medium"
          icon={ reloadOutline }
          color = { iconColor }
          onClick =
            {
              async () =>
                {
                  if( navigator.onLine ) {
                    await DBInit_useTTLChange( false ) ;
                    await DBInitedChange( false ) ;
                  } else {
                    setIconColor( 'warning' ) ;
                  }
                }
            }
        />
      </IonItem>
    </>
  );
}

export default DataReload ;
