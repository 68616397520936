/** ENV_SETTINGS.tsx 
 *  - "Bridge" zu public/env/settings.js
 *    - settings.js soll außerhalb von "src" sein
 *    - bei jedem Zugriff auf JS außerhalb von "src" ein "@ts-ignore" erforderlich, deshalb hier eine
 *      Bridge, mit der nur einmal ein "@ts-ignore" erforderlich, bei Zugriff via exportiertem SETTINGS
 *      hier aber nicht
 */

// @ts-ignore
export let ENV_SETTINGS = ENV_SETTINGS_JS ;
