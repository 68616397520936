import { useContext, useState, useEffect } from 'react';
import { DataContext } from '../context/DataContext';
import { dbQuery } from '@saint/sqlite-worker';
import { chevronForward, chevronBack, addCircleOutline, trashBinOutline, search, add, cog, swapVertical, arrowDown } from 'ionicons/icons';
import {
    IonGrid,
    IonLabel,
    IonRow,
    IonCol,
    IonInput,
    IonContent,
    IonPage,
    IonCard,
    IonItem,
    IonList,
    IonButton,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
    IonFab,
    IonFabButton,
    IonFabList,
} from '@ionic/react';
import { bworkerQuery } from '@saint/fetch-worker';
import Box from '@mui/material/Box';
import { LieferantArtikelUebersichtArtikel } from '../components/LieferantArtikelUebersichtArtikel';
import zIndex from '@mui/material/styles/zIndex';

const LieferantenArtikelUebersicht: React.FC = () => {
    const { server, appChosenKundenId } = useContext(DataContext);

    const [localLieferantenArtikelUebersicht, setLocalLieferantenArtikelUebersicht] = useState([]);
    const [localLieferantenArtikelGesamt, setLocalLieferantenArtikelGesamt] = useState([]);
    const [localLieferantenArtikelOffset, setLocalLieferantenArtikelOffset] = useState(0);
    const [localLieferantenArtikelSuche, setLocalLieferantenArtikelSuche]: any = useState('');
    const [localLieferantenArtikelSortierung, setLocalLieferantenArtikelSortierung]: any = useState('artikel_lieferanten_artikel_nr');

    const [localLieferantenZusatzstoffliste, setLocalLieferantenZusatzstoffliste] = useState([]);

    const [localShowNeuerArtiel, setLocalShowNeuerArtiel]: any = useState(false);

    function LieferantenArtikelUebersichtLadeDaten() {
        // console.log('localLieferantenArtikelSuche', localLieferantenArtikelSuche);
        dbQuery(
            `SELECT * FROM saint_artikel,saint_preise 
        WHERE artikel_id LIKE '${appChosenKundenId}-%' 
        AND artikel_id = preise_artikel_id
        AND (
                artikel_id LIKE '%${localLieferantenArtikelSuche}%'
            OR
                artikel_bezeichnung LIKE '%${localLieferantenArtikelSuche}%'
            OR
                artikel_beschreibung LIKE '%${localLieferantenArtikelSuche}%'
            OR
                artikel_lieferanten_artikel_nr LIKE '%${localLieferantenArtikelSuche}%'
            )
             `,
            setLocalLieferantenArtikelGesamt,
        );
        dbQuery(
            `SELECT * FROM saint_artikel,saint_preise 
        WHERE artikel_id LIKE '${appChosenKundenId}-%' 
        AND artikel_id = preise_artikel_id
        AND (
            artikel_id LIKE '%${localLieferantenArtikelSuche}%'
            OR
            artikel_bezeichnung LIKE '%${localLieferantenArtikelSuche}%'
            OR
            artikel_beschreibung LIKE '%${localLieferantenArtikelSuche}%'
            OR
            artikel_lieferanten_artikel_nr LIKE '%${localLieferantenArtikelSuche}%'
            )

        ORDER BY
            ${localLieferantenArtikelSortierung}
        LIMIT ${localLieferantenArtikelOffset},20
             `,
            setLocalLieferantenArtikelUebersicht,
        );

        dbQuery(
            `SELECT * FROM saint_zusatzstoffe
        WHERE zusatzstoff_id LIKE '${appChosenKundenId}-%'`,
            setLocalLieferantenZusatzstoffliste,
        );
    }

    useEffect(() => {
        if (appChosenKundenId) {
            LieferantenArtikelUebersichtLadeDaten();
        }
    }, [appChosenKundenId, localLieferantenArtikelOffset, localLieferantenArtikelSuche]);

    useEffect(() => {
        if (appChosenKundenId) {
            LieferantenArtikelUebersichtLadeDaten();
        }
    }, [appChosenKundenId, localLieferantenArtikelOffset, localLieferantenArtikelSuche, localLieferantenArtikelSortierung]);

    // useEffect(() => {
    //     //console.log('localLieferantenArtikelUebersicht', localLieferantenArtikelUebersicht.values);
    // }, [localLieferantenArtikelUebersicht]);

    // useEffect(() => {
    //     console.log('localLieferantenArtikelGesamt', localLieferantenArtikelGesamt.values);
    // }, [localLieferantenArtikelGesamt]);

    return (
        <IonPage
            style={{
                background: 'url("../assets/food-bg.jpg")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
            <IonContent>
                <IonCard style={{ height: '92%', overflow: 'auto' }}>
                    
                    <IonCardContent>
                        <table className="datatable" id="datatable">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 5,
                                            maxWidth: '100px',
                                            minWidth: '100px',
                                        }}>
                                        &nbsp;
                                    </th>
                                    <th style={{ position: 'sticky', left: "100px",  zIndex: 5 }}>
                                        Bestellnummer
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_lieferanten_artikel_nr'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_lieferanten_artikel_nr')} />
                                    </th>
                                    <th className="datacelllarge">
                                        Bezeichnung
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_bezeichnung'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_bezeichnung')} />
                                        
                                    </th>
                                    <th className="datacelllarge">
                                        Beschreibung
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_beschreibung'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_beschreibung')} />
                                    </th>
                                    <th>
                                        VE
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_bestelleinheit'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_bestelleinheit')} />
                                    </th>
                                    <th>
                                        Inhalt VE
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_inhalt_bestelleinheit'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_inhalt_bestelleinheit')} />
                                    </th>
                                    <th>
                                        Einheit Inhalt
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_basiseinheit'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_basiseinheit')} />    
                                    </th>
                                    <th>
                                        Gewicht je Inhalt in g
                                        <IonIcon icon={localLieferantenArtikelSortierung==='artikel_rezept_faktor'? arrowDown : swapVertical} size='small' onClick={()=>setLocalLieferantenArtikelSortierung('artikel_rezept_faktor')} />
                                    </th>
                                    <th className="datacelllarge">Kennzeichnung</th>
                                    <th>Preis je VE</th>
                                    <th>Preis ab</th>
                                    <th>Steuer</th>
                                    <th>KCal je 100g</th>
                                    <th>Fett je 100g</th>
                                    <th>gesättigte FS je 100g</th>
                                    <th>Kohlenhydrate je 100g</th>
                                    <th>Zucker je 100g</th>
                                    <th>Eiweiß je 100g</th>
                                    <th>Salz je 100g</th>
                                    <th>aktiv</th>
                                </tr>
                            </thead>
                            <tbody>
                                {localLieferantenArtikelUebersicht.values.length > 0 ? (
                                    //@ts-ignore
                                    localLieferantenArtikelUebersicht.values.map((artikel: any) => {
                                        return (
                                            <LieferantArtikelUebersichtArtikel
                                                key={'artikel_' + artikel.artikel_id}
                                                artikel={artikel}
                                                zusatzstoffe={localLieferantenZusatzstoffliste}
                                            />
                                        );
                                    })
                                ) : (
                                    <tr>
                                        {
                                           //@ts-ignore 
                                           <td colspan="10">Keine Artikel
                                           <IonButton onClick={async ()=> {
                                                await setLocalShowNeuerArtiel(true)
                                                //@ts-ignore
                                                document.getElementById('neuer_artikel').scrollIntoView()
                                                }}
                        >
                            <IonIcon icon={add}></IonIcon>
                        Neuen Artikel anlegen

                        </IonButton>
                                           </td>
                                        }
                                        
                                        
                                    </tr>
                                )}
                                {localShowNeuerArtiel ? (
                                    <LieferantArtikelUebersichtArtikel 
                                        zusatzstoffe={localLieferantenZusatzstoffliste}
                                    />
                                ) : null}
                            </tbody>
                        </table>
                    </IonCardContent>
                </IonCard>
                <IonFab
                    slot="fixed"
                    horizontal="end"
                    vertical="bottom"
                    
                    >
                    <IonFabButton>
                        <IonIcon icon={cog}></IonIcon>
                    </IonFabButton>
                    <IonFabList side="top">
                        <IonButton>
                            <IonIcon icon={search}></IonIcon>
                            <IonLabel>
                                <IonInput
                                    placeholder="Suche"
                                    clearInput={true}
                                    debounce={300}
                                    type="text"
                                    onIonChange =
                                      {
                                        ( e ) =>
                                          {
                                            if( localLieferantenArtikelOffset !== 0 ) {
                                              console.log( 'reset localLieferantenArtikelOffset' ) ;
                                              setLocalLieferantenArtikelOffset( 0 ) ;
                                            }
                                            setLocalLieferantenArtikelSuche(e.target.value) ;
                                          }
                                      }
                                  ></IonInput>
                            </IonLabel>
                        </IonButton>

                        <IonButton onClick={async ()=> {
                            await setLocalShowNeuerArtiel(true)
                            //@ts-ignore
                            document.getElementById('neuer_artikel').scrollIntoView()
                            }}
                        >
                            <IonIcon icon={add}></IonIcon>
                            <IonLabel>Neuer Artikel</IonLabel>

                        </IonButton>
                    </IonFabList>
                    <IonFabList side="start">
                        {localLieferantenArtikelOffset + 20 < localLieferantenArtikelGesamt.values.length ? (
                            <IonButton
                                onClick={() => setLocalLieferantenArtikelOffset(localLieferantenArtikelOffset + 20)}
                                >
                                <IonIcon icon={chevronForward} />
                            </IonButton>
                        ) : null}
                        <IonButton onClick={() => LieferantenArtikelUebersichtLadeDaten()}
                                                    >
                            laden {localLieferantenArtikelOffset + 1} -{' '}
                            {localLieferantenArtikelOffset + 20 > localLieferantenArtikelGesamt.values.length
                                ? localLieferantenArtikelGesamt.values.length
                                : localLieferantenArtikelOffset + 20}{' '}
                            ({localLieferantenArtikelGesamt.values.length})
                        </IonButton>

                        {localLieferantenArtikelOffset !== 0 ? (
                            <IonButton
                                onClick={() => setLocalLieferantenArtikelOffset(localLieferantenArtikelOffset - 20)}
                                >
                                <IonIcon icon={chevronBack} />
                            </IonButton>
                        ) : null}
                        
                    </IonFabList>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default LieferantenArtikelUebersicht;

